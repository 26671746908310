<template>
    <div style="text-align: center">
        <img src="../assets/img/noPerMissions.png" alt="" style="width: 280px;height: auto;margin-top: 18vh">
        <p style="font-weight: 700;font-size: 32px;margin-top: 8vh">无权限</p>
        <p style="margin-top: 2vh;font-size: 24px">请联系系统管理员</p>
    </div>
</template>

<script>
    import {getSignature} from "@/api/api";

    export default {
        name: "noPermissions",
        methods:{
            async   getSignature(agentid,appid){
                let url = window.location.href.split('#')[0]
                try {
                    let data = {
                        url:url,
                        agentId:agentid
                    }
                    console.log('data')
                    console.log(data)
                    let res = await getSignature(data)
                    console.log(res)
                    let result = JSON.parse(res)
                    console.log(result)
                    wx.agentConfig({
                        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        corpid: appid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                        agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
                        timestamp: Number(result.timestamp), // 必填，生成签名的时间戳
                        nonceStr: String(result.nonce_str), // 必填，生成签名的随机串
                        signature: String(result.signature),// 必填，签名，见附录-JS-SDK使用权限签名算法
                        jsApiList: ['onHistoryBack','closeWindow','onMenuShareAppMessage'], //必填，传入需要使用的接口名称
                        success: function (res) {
                            // wx.closeWindow();
                            let url = JSON.parse(sessionStorage.getItem("url"))
                            window.wx.onHistoryBack(function(){
                                if(window.location.pathname == '/overviewJH'){
                                    window.wx.closeWindow();
                                }else{
                                    window.location.href = url
                                }
                                return true
                            })
                            wx.onMenuShareAppMessage({
                                title: '舆情报告', // 分享标题
                                desc: '随时查阅舆情报告，及时掌握关键信息。 ', // 分享描述
                                link: window.location.href, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                                imgUrl: 'http://inews5.xlmediawatch.com/reportFile/asslogjs/1047/lnewsImg.png', // 分享图标
                                success: function () {
                                    // 用户确认分享后执行的回调函数
                                },
                                cancel: function () {
                                    // 用户取消分享后执行的回调函数
                                }
                            });

                        },
                        fail: function (res) {
                            // alert("err")
                            // alert(JSON.stringify(res))
                            if (res.errMsg.indexOf('function not exist') > -1) {
                                alert('版本过低请升级')
                            }
                        }
                    });
                }
                catch (e) {
                    console.log(e)
                }

            },
        },
        created() {
            // let agentid = JSON.parse(sessionStorage.getItem("agentid"))
            // let appid = JSON.parse(sessionStorage.getItem("appid"))
            // if(agentid){
            //     this.getSignature(agentid,appid)
            // }
        }
    }
</script>

<style scoped>

</style>